<template>
  <v-app class="bg-light">
    <div class="container" v-show="shows.showNotFound">
      <div class="col-12" :style="{ height: windowWidth }">
        <div class="col-12 text-center" style="margin-top: 15%; height: 120px">
          <p class="h1 text-center" :class="errColor">
            {{ err[erri] }}
          </p>
        </div>
      </div>
    </div>

    <div v-show="shows.loader">
      <div
        class="d-flex justify-content-center mx-auto"
        style="max-width: 800px; height: 700px"
      >
        <div
          class="spinner-border h1 text-primary"
          role="status"
          style="height: 90px; width: 90px; margin-top: 25%"
        ></div>
      </div>
    </div>

    <b-container v-show="shows.showArticle">
      <b-container class="d-flex p-0">
        <div
          class="col-xs-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 mx-auto"
        >
          <b-card no-body>
            <b-card-header header-bg-variant="white" class="px-2 py-1">
              <div class="d-flex flex-row align-items-center">
                <v-avatar class="me-2 border" size="40">
                  <v-img
                    :src="
                      postHolder.publisherPic != null
                        ? postHolder.publisherPic
                        : require('@/assets/images/profile.jpg')
                    "
                  ></v-img>
                </v-avatar>
                <span class="small">
                  {{ postHolder.publisherName }}
                </span>
              </div>
            </b-card-header>
            <b-row no-gutters class="d-flex">
              <div class="col-12 h-auto">
                <div v-if="shows.videoPlayerShow">
                  <b-embed
                    type="video"
                    aspect="1by1"
                    allowfullscreen
                    controls
                    controlsList="nodownload"
                    :poster="postHolder.picture"
                    class="w-100"
                  >
                    <source :src="postHolder.url" type="video/mp4" />
                  </b-embed>
                </div>
                <!-- end of the video player -->

                <!-- image slider -->
                <div v-if="shows.postImage">
                  <div>
                    <v-img
                      fluid-grow
                      :src="postHolder.picture"
                      :lazy-src="require('@/assets/images/placeholder.png')"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </div>
              </div>
              <!-- end of the image slider -->
              <!-- comment section -------------------->

              <!-- end of the (comment section)------------------------------------ -->
              <div class="col-12 border-right h-auto">
                <div class="d-flex flex-column">
                  <div class="d-flex flex-column px-4 py-2">
                    <div class="d-flex flex-row">
                      <div @click="setLikeArticle(!postHolder.liked)">
                        <i
                          class="fas fa-heart cursor fa-lg text-danger"
                          v-if="postHolder.liked"
                        >
                        </i>
                        <i
                          class="far fa-heart cursor fa-lg"
                          v-if="!postHolder.liked"
                        ></i>
                      </div>
                      <div class="mx-3" @click="scrollToComment('comment')">
                        <i class="far fa-comment cursor fa-lg"> </i>
                      </div>
                      <!-- <div @click="shareArticle()">
                            <i
                            class="far fa-paper-plane cursor fa-lg flip-x"
                            ></i>
                        </div> -->
                      <div
                        class="ms-auto"
                        @click="setBookmarkArticle(!postHolder.isBookmarked)"
                      >
                        <i
                          class="fas fa-bookmark cursor fa-lg text-black"
                          v-if="postHolder.isBookmarked"
                        ></i>
                        <i
                          class="far fa-bookmark cursor fa-lg"
                          v-if="!postHolder.isBookmarked"
                        ></i>
                      </div>
                    </div>

                    <div class="mt-1">
                      <div class="mt-1 d-flex flex-row">
                        <span class="small">
                          {{ postHolder.likeNumber }} لایک
                        </span>
                        <span class="text-secondary small px-6">
                          {{ postHolder.viewCount }} بازدید
                        </span>
                        <span class="text-black-50 small ms-auto">
                          {{ DateCalculate(postHolder.updateDate) }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="border-top">
                    <div class="d-flex flex-column">
                      <div class="mx-2">
                        <div class="d-flex flex-column align-content-center">
                          <div class="d-flex flex-column">
                            <span class="medium font-weight-bold p-2" v-html="postHolder.title">
                            </span>
                            <span
                              class="small px-3"
                              v-html="postHolder.description"
                            ></span>
                            <hr class="mt-1" />
                          </div>

                          <div
                            v-for="(comment, index) in comments"
                            :key="index"
                          >
                            <div class="d-flex flex-row align-items-center">
                              <v-avatar class="profile ms-2 border" size="36">
                                <v-img
                                  :src="
                                    comment.authorPicture != null
                                      ? comment.authorPicture
                                      : require('@/assets/images/profile.jpg')
                                  "
                                ></v-img>
                              </v-avatar>
                              <span class="small font-weight-bold ms-2">
                                {{ comment.authorName }}
                              </span>
                            </div>
                            <div class="d-flex flex-column">
                              <div
                                class="d-flex flex-row align-items-center p-2"
                              >
                                <span class="small flex-grow-1 px-2" v-html="comment.comment">
                                </span>
                              </div>

                              <div class="d-flex flex-row">
                                <small class="text-black-50 ms-2">
                                  {{ DateCalculate(comment.createDate) }}
                                </small>
                                <small class="text-black-50 ms-4">
                                  {{ comment.likeNumber }} لایک
                                </small>

                                <i
                                  class="fas fa-heart cursor me-2 text-danger ms-auto"
                                  v-if="comment.liked"
                                  @click="setLikeComment(false)"
                                ></i>
                                <i
                                  class="far fa-heart cursor me-2 ms-auto"
                                  v-else
                                  @click="setLikeComment(true)"
                                ></i>
                              </div>
                            </div>
                            <hr class="mt-1" />
                          </div>
                        </div>

                        <div>
                          <span
                            v-for="(item, index) in postHolder.groups"
                            :key="index"
                            class="
                              bg bg-secondary
                              text-light
                              mx-1
                              mb-2
                              d-inline-block
                              px-1
                              py-1
                              cursor
                            "
                            style="
                              font-size: 12px;
                              border-radius: 7px;
                              font-weight: 200;
                            "
                          >
                            {{ item.value }}
                          </span>
                        </div>
                      </div>

                      <section class="px-2 py-3 border-top" ref="comment">
                        <form
                          class="d-flex align-items-center"
                          v-on:submit.prevent="submitForm"
                        >
                          <!-- <div class="me-3"> -->
                          <!-- <button class="" type="button">
                              <i class="far fa-smile-beam cursor fa-lg"></i>
                            </button> -->
                          <!-- <div style="right: 12px; bottom: 360px"></div> -->
                          <!-- </div> -->

                          <textarea
                            placeholder="دیدگاه شما ..."
                            class="form-control"
                            autocomplete="off"
                            autocorrect="off"
                          >
                          </textarea>

                          <button class="ms-3" type="submit">ارسال</button>
                        </form>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </b-row>
          </b-card>
        </div>
      </b-container>
    </b-container>
  </v-app>
</template>

<script>
import apiService from "@/http/ApiServiceWebsite.js";
var moment = require("moment");

export default {
  data() {
    return {
      postHolder: {},
      Profile: {},
      comments: [],

      shows: {
        postImage: false,
        imageCounter: false,
        imageShow: false,
        videoPlayerShow: false,

        showArticle: false,
        showNotFound: false,
        loader: true,
      },

      images: [],
      currentIndex: 0,
      currentImageSlideNumber: 1,
      chooseSlide: "",

      err: [
        " خطا در پردازش ",
        "صفحه مورد نظر یافت نشد !",
        "محتوایی یافت نشد !",
      ],
      erri: 0,
      errColor: "text-danger",
      windowWidth: window.innerHeight,
    };
  },

  methods: {
    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    },
    sliderloader(pic) {
      this.images = [];
      if (typeof pic === "string") {
        this.images.push(pic);
      }
      this.shows.postImage = true;
      this.shows.imageShow = true;

      if (this.images.length > 1) {
        this.shows.imageCounter = true;
      } else {
        this.shows.imageCounter = false;
      }
      let a = Math.abs(this.currentIndex) % this.images.length;
      this.currentImageSlideNumber = a + 1;
      let f = Math.abs(this.currentIndex) % this.images.length;
      this.chooseSlide = this.images[f];
    },
    postLoader(type, id) {
      if (type == "video") {
        apiService
          .videoInfo(id)
          .then((DATA) => {
            if (DATA.status == 200) {
              this.postHolder = DATA.data;
            document.title = "مایندولوژی | " + DATA.data.title;
              this.shows.videoPlayerShow = true;
              this.shows.showArticle = true;
              this.shows.loader = false;
              this.shows.showNotFound = false;

              this.fetchVideoComment(id);
            } else {
              this.shows.loader = false;
              this.shows.showArticle = false;
              this.shows.showNotFound = true;
              this.errColor = "text-danger";
              this.erri = 1;
            }
          })
          .catch((error) => {
            this.shows.loader = false;
            this.shows.showNotFound = true;
            this.shows.showArticle = false;

            this.errColor = "text-danger";
            if (error.response.status == 422 || error.response.status == 500) {
              this.erri = 1;
            } else {
              this.erri = 0;
            }
          });
      } else if (type == "post") {
        apiService
          .postInfo(id)
          .then((DATA) => {
            if (DATA.status == 200) {
              this.postHolder = DATA.data;
            document.title = "مایندولوژی | " + DATA.data.title;
              this.sliderloader(DATA.data.picture);

              this.shows.showArticle = true;
              this.shows.loader = false;
              this.shows.showNotFound = false;

              this.fetchPostComment(id);
            } else {
              this.shows.loader = false;
              this.shows.showNotFound = true;
              this.shows.showArticle = false;
              this.errColor = "text-danger";
              this.erri = 1;
            }
          })
          .catch((error) => {
            this.shows.loader = false;
            this.shows.showNotFound = true;
            this.shows.showArticle = false;
            this.errColor = "text-danger";
            if (error.response.status == 422 || error.response.status == 500) {
              this.erri = 1;
            } else {
              this.erri = 0;
            }
          });
      }
    },
    fetchPostComment(id) {
      apiService.postCommentList(id).then((response) => {
        this.comments = response.data.content;
      });
    },
    fetchVideoComment(id) {
      apiService.videoCommentList(id).then((response) => {
        this.comments = response.data.content;
      });
    },
    DateCalculate(then) {
      var now = new Date();
      var diffDays = moment(now).diff(moment(then), "days");
      if (diffDays < 1) {
        return "امروز";
      } 
      else if(diffDays > 1 && diffDays < 7) {
        return diffDays + " روز پیش";
      }
      else if (diffDays >= 7 && diffDays <= 28) {
        return Math.round(diffDays/7) + " هفته پیش"
      }
      else if (diffDays > 28 && diffDays <= 365) {
        return Math.round(diffDays/30) + " ماه پیش"
      }
      else if (diffDays > 365) {
        return Math.round(diffDays/365) + " سال پیش";
      }
      else {
        return diffDays + " روز پیش";
      }
    },
    scrollToComment(refName) {
      var element = this.$refs[refName];
      element.scrollIntoView({ behavior: "smooth", block: "end" });
    },
    setLikeArticle(like) {
      if (!this.isLoggedIn) {
        this.$bvToast.toast(
          `برای دسترسی به این قسمت لطفا اپلیکیشن مایندولوژی را دانلود نمائید`,
          {
            to: '/download',
            title: `دسترسی `,
            toaster: "b-toaster-bottom-left",
            solid: true,
            variant: "danger",
            appendToast: false,
            noCloseButton: true,
          }
        );
      }
    },
    setBookmarkArticle(bookmark) {
      if (!this.isLoggedIn) {
        this.$bvToast.toast(
          `برای دسترسی به این قسمت لطفا اپلیکیشن مایندولوژی را دانلود نمائید`,
          {
            to: '/download',
            title: `دسترسی `,
            toaster: "b-toaster-bottom-left",
            solid: true,
            variant: "danger",
            appendToast: false,
            noCloseButton: true,
          }
        );
      }
    },
    setLikeComment(like) {
      if (!this.isLoggedIn) {
        this.$bvToast.toast(
          `برای دسترسی به این قسمت لطفا اپلیکیشن مایندولوژی را دانلود نمائید`,
          {
            to: '/download',
            title: `دسترسی `,
            toaster: "b-toaster-bottom-left",
            solid: true,
            variant: "danger",
            appendToast: false,
            noCloseButton: true,
          }
        );
      }
    },
    addComment(comment) {
      if (!this.isLoggedIn) {
        this.$bvToast.toast(
          `برای دسترسی به این قسمت لطفا اپلیکیشن مایندولوژی را دانلود نمائید`,
          {
            to: '/download',
            title: `دسترسی `,
            toaster: "b-toaster-bottom-left",
            solid: true,
            variant: "danger",
            appendToast: false,
            noCloseButton: true,
          }
        );
      }
    },
    submitForm(e) {
      // To prevent the form from submitting
      if (!this.isLoggedIn) {
        this.$bvToast.toast(
          `برای دسترسی به این قسمت لطفا اپلیکیشن مایندولوژی را دانلود نمائید`,
          {
            to: '/download',
            title: `دسترسی `,
            toaster: "b-toaster-bottom-left",
            solid: true,
            variant: "danger",
            appendToast: false,
            noCloseButton: true,
          }
        );
      }
    },
    // shareArticle(){
    // },
  },
  beforeMount() {
    const id = this.$route.params.id;
    const type = this.$route.params.type;
    this.postLoader(type, id);
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>
<style>
.flip-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
